body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* overflow: hidden; */
  overflow-y: scroll;
  background-color: #f8f8f8;
}

/* style.css */

@font-face {
  font-family: "gm";
  src: local("gm"),
    url("../src/Assets/Fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "gb";
  src: local("gb"),
    url("../src/Assets/Fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "gr";
  src: local("gr"),
    url("../src/Assets/Fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "gsb";
  src: local("gsb"),
    url("../src/Assets/Fonts/Gilroy-SemiBold.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
}
table {
  table-layout: fixed;
  width: 300px;
}
.outer_div {
  background: #f9f9f9;
  border-radius: 2px;
  padding: 25px;
}
.border_td {
  border: 1px solid lightgrey;
  padding: 10px;
}
.header_sec {
  padding: 8px;
}
.potable_input {
  border: 0px !important;
  outline: none;
  padding: 5px;
  font-size: 13px;
}
.₹ {
  background: #ffecb5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px 0px 0px 2px;
  /* height: 31px; */
  width: 30px;
  height: 30px;
  font-size: 18px;
}
.main_head {
  font-size: 20px;
  font-family: Gb;
}
.table_head {
  font-size: 16px;
  font-family: Gsb;
}
.t_body {
  font-family: Gm;
  font-size: 13px;
}
.Cal_details {
  border-left: 1px solid lightgrey;
}
.Cal_text {
  font-size: 14px;
  font-family: Gm;
}
.calc {
  border-top: none !important;
  border: 1px solid lightgrey;
}
.total_mt {
  font-family: Gb;
  font-size: 18px;
  color: black;
}
.in_words {
  border-top: none !important;
  border: 1px solid lightgrey;
  padding: 8px;
  font-size: 13px;
  font-family: Gm;
}
.del_details {
  font-family: Gsb;
  margin-top: 20px;
}
.del_input {
  border: none;
  font-size: 13px;
  background: #f6f6f6;
  padding: 10px;
  font-family: Gr;
  border-radius: 2px;
  cursor: pointer;
}
.submit_det {
  padding: 10px 40px;
  font-size: 15px;
  border: none;
  background: #f7b314;
  border-radius: 2px;
  color: #000;
  font-family: Gb;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.calButton {
  background: linear-gradient(91.55deg, #ffe185 4.49%, #f7ba00 100.45%);
  border-radius: 2px;
  padding: 10px;
  margin-right: 10px;
}

.mobile_section {
  background-color: #fff;
  display: none;
  overflow: hidden;
}
.main_div {
  overflow: hidden !important;
}
.contact_us_text {
  font-family: Gb;
}
.rfq_mobile_main {
  background-color: rgba(249, 249, 249, 0.64);
}
.matr_name {
  padding: 10px;
  color: #fff;
  font-family: Gm;
  background: linear-gradient(95.67deg, #626262 3.24%, #151414 107.53%);
}
.mater_header {
  font-family: Gm;
  margin-top: 10px;
  margin-bottom: 8px;
  color: #000;
}
.mob_input_fields {
  border: none;
  padding: 10px;
  width: 100%;
  font-family: Gm;
}
.mob_tag {
  font-family: Gsb;
  background: #ffe185;
  border-radius: 2px 0px 0px 2px;
  height: 2.8rem;
  padding: 10px;
}
.total_amt_mob {
  font-family: Gb;
}
.mob_sub_total_div {
  margin-top: 2rem;
  background: #fef7e7;
  padding: 15px;
}
.sub_total {
  font-family: Gsb;
}
.other_fields {
  font-family: Gm;
  font-size: 14px;
}
.mob_main_heading {
  font-size: 24px;
  font-family: Gb;
}
.mob_subheading {
  font-family: Gsb;
}
.mob_cal_btn {
  width: 50%;
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  color: #000;
  border: none;
  font-family: Gsb;
  padding: 10px;
}
/* Footer */
.footer {
  color: #666;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
  margin-top: 50px;
}
.rowMainFooter {
  /* padding: 65px 30px; */
}
.boxCopyright {
  text-align: center;
  color: #000;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  padding: 24px 0px;
  background-color: #ececec;
}

.boxCopyright p {
  font-family: Gm;
}

.footerLogo {
  width: 150px;
  margin-bottom: 30px;
}
.footerLogo img {
  width: 100%;
}
.rowMainFooter h4 {
  text-transform: none;
  color: #000;
  padding-bottom: 12px;
  font-family: Gb;
  font-size: 26px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.rowMainFooter h4:after {
  width: 40px;
  height: 4px;
  bottom: 0px;
  left: 0px;
  content: "";
  position: absolute;
  background-color: #fdc315;
}

ul.socialIcons li a:hover {
  color: #fdc315;
}
ul.socialIcons {
  font-size: 20px;
  list-style: none;
  padding-left: 0px;
}
ul.socialIcons li {
  display: inline-block;
}
ul.socialIcons li + li {
  margin-left: 20px;
}
ul.socialIcons li a {
  color: #000;
}

ul.listContactInfo {
  list-style: none;
  padding-left: 0px;
}
ul.listContactInfo li:first-child {
  border-top: none;
}
ul.listContactInfo li {
  position: relative;
  padding-left: 33px;
  padding-top: 20px;
  margin-top: 15px;
  border-top: solid 1px #cbcbcb;
}
ul.listContactInfo li a {
  color: #000;
  text-decoration: none;
  font-family: Gm;
}
ul.listContactInfo li a:hover {
  color: #fdc315;
}

.land_li_icons {
  position: absolute;
  left: 0px;
  color: #fdc315;
  top: 23px;
}
.mob_cal_btn {
  /* display: none; */
  background: #fdc315;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-family: Gsb;
}
.mob_cal_btn1 {
  /* display: none; */
  background: #fdc315;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-family: Gsb;
}
.errors {
  color: red !important;
}
.otp_sent {
  font-family: fm;
  font-size: 15px;
  color: green;
}

.otp_worong {
  color: red;
  font-family: fm;
}
#signup {
  display: none;
}
.get_free_quote_btn {
  border: 1px solid #000;
  background-color: #fff;
}
.thank_subheading {
  color: #969696;
  font-size: 18px;
  font-family: Gm;
}
.rights_tag {
  background: #f9f9f9;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  font-family: "gm";
}
.contact_mob {
  display: block;
}
.socila_mob {
  display: block;
}
.contact_us_no {
  text-decoration: none;
  color: #000;
}
.contact_us_no:hover {
  text-decoration: none !important;
  color: #000 !important;
}
a:hover {
  text-decoration: none !important;
  color: #000 !important;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .FooterTopcontainer{
    display: block;
    flex-wrap: nowrap;
    margin-top: 0px;
    margin-right:0px;
    margin-left: 0px;
  }
  .FooterSubcontainer{
    width: 100%;
  }
  .mob_cal_btn {
    width: 100%;
  }
  .contact_mob {
    display: block;
  }
  .socila_mob {
    display: block;
  }
  .rights_tag {
    background: #f9f9f9;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    font-family: "gm";
  }
  .footer {
    font-size: 14px;
  }
  .main_div {
    overflow: hidden;
    display: none;
  }
  .mobile_section {
    display: block;
  }
}

.succesmsg {
  background: #ffffff;
  box-shadow: 0px 41px 46px -25px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 332px;
  height: 237px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 17px;
}
.Successfully {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}
.errorfile {
  border: 1px solid red !important;
}
.containerterms {
  background-color: #ffffff;
}

/* Discount Material */
.discountinput {
  width: 65px;
  padding-left: 11px;
}
.discounttopdiv {
  position: relative;
}
.MaterialDiscount {
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: -56px;
  display: block;
  z-index: 100;
}
.MaterialDiscount > p {
  width: 77px;
  height: 30px;
  flex-shrink: 0;
  color: #000;
  font-family: "GR";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.percetnagepara {
  padding-top: 14px;
  margin-bottom: 0px;
}
.amountpara {
  padding-top: 14px;
  margin-bottom: 14px;
}
.input-euro {
  position: relative;
}
.input-euro.left input {
  padding-left: 18px;
}
.input-euro.right input {
  padding-right: 18px;
  text-align: end;
}

.input-euro:before {
  position: absolute;
  top: 5px;
  content: "₹";
}
.input-euro.left:before {
  left: 8px;
}
.input-euro.right:before {
  right: 5px;
}
.perctange {
  position: relative;
}

.perctange input {
  padding-right: 33px;
  text-align: start;
}

.perctange::after {
  position: absolute;
  top: 5px;
  right: 22px;
  content: "%";
  pointer-events: none;
}
.groundtotal {
  cursor: not-allowed;
}

@media screen and (max-width: 500px) {
  .MvsubtotaltitleContainer {
    padding-left: 105px;
  }
  .DeliveryDatepara {
    color: #000;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
  }
  .react-datepicker__input-container {
    width: 149px !important;

  }
  .react-datepicker__calendar-icon {
    margin-left: 120px;
  }
  .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 8px !important;
  }
  .MvstotaldiscountContainer {
    padding-left: 77px;
  }
  .subtotaltitle {
    color: #000;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .totaldiscountheading {
    color: #000;
    text-align: right;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .Transportationtitle {
    color: #000;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
  }
  .Loadingtitle {
    color: #000;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
  }
  .MaterialDiscount {
    left: -44px;
  }
  .MaterialDiscount > p {
    height: 24px;
  }
  .percetnagepara {
    padding-left: 6px;
    padding-top: 12px;
    margin-bottom: 5px;
  }
  .amountpara {
    padding-top: 16px;
    padding-left: 6px;
    margin-bottom: 16px;
  }
  .totalMobileXyz {
    margin-top: 38px;
  }
  .Deliverydate {
    color: #000;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .uommobile {
    width: 148px;
    height: 48px;
    background-color: #dbdbdb;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 16px;
    color: #000;
    font-family: "gsb";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .loadingmv {
    color: #000;
    text-align: right;
    font-family: gsb;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mobilebox {
    width: 33px;
    height: 39px;
  }
  .xyzper {
    width: 54.89px;
    height: 39px;
  }

  .perctange input {
    padding-right: 73px;
    text-align: start;
  }

  .perctange::after {
    position: absolute;
    top: 5px;
    right: 80px;
    content: "%";
    pointer-events: none;
  }
  .input-euro:before {
    position: absolute;
    top: 6px;
    content: "₹";
  }
  .₹ {
    background: #ffe185;
  }
}
.discounttopdiv {
  border: none;
}
